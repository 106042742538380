import $ from "jquery";
import createRipple from "/js/components/ripple.js";
import prestashop from "prestashop";
import toastr from '../components/toastr'

// Ripple init
let addToCartButton = '.cart-icon[data-button-action="add-to-cart"]';
// let button = $(addToCartButton);

$('body').on('click', addToCartButton, createRipple);


$('body').on('click', addToCartButton, function () {
    $(this).addClass('loading');
});



function resolveLoader(state, element, message) {
    element.find('.cart-spinner circle').one('animationiteration', function () {
        let button = $(this).closest(addToCartButton);
        button.removeClass('loading').addClass(state);
        message.forEach((message) => {
            toastr[state](message);
        })
        setTimeout(function () {
            button.removeClass(state);
        }, 2000);
    });
}




$(function () {
    prestashop.on("updateCart", function (event) {
        if (
            event &&
            event.reason &&
            typeof event.resp !== "undefined" &&
            event.reason.linkAction !== "add-to-cart"
        ) { return }

        let id = event.reason.element;
        if (!id) {
            return console.error('Error handling "add-to-cart" action: Please use the recompiled core.js available for this theme.');
        }
        let element = $(`${addToCartButton}[data-id="${id}"]`);
        let successMessage = []
        successMessage.push(element.data('success-message'));
        if (!event.resp.hasError) {
            resolveLoader('success', element, successMessage);
        } else {
            resolveLoader('error', element, event.resp.errors);
        }
    });
});