import $ from "jquery";
import prestashop from "prestashop";

$(document).ready(() => {
    const parseSearchUrl = function (event) {
        if (event.target.dataset.searchUrl !== undefined) {
            return event.target.dataset.searchUrl;
        }

        if ($(event.target).parent()[0].dataset.searchUrl === undefined) {
            throw new Error("Can not parse search URL");
        }

        return $(event.target).parent()[0].dataset.searchUrl;
    };

    $("body").on(
        "change",
        "[data-custom-sort-facet] input[data-search-url]",
        function (event) {
            prestashop.emit("updateFacets", parseSearchUrl(event));
        }
    );
});
