import { debounce } from "../helpers.js";
import SimpleBar from "simplebar";
import $ from "jquery";

$(function() {    

    $( document ).ready(function() {
        createMenuScrollBar()
        $(window).on('resize', createMenuScrollBar);
        
        $(document).on('click','[data-megamenu-mobile-toggle]', e => {
            // if($(window).width() < 1600) {
                $('.megamenu').toggleClass('megamenu-hidden')
                e.currentTarget.classList.toggle('megamenu-open');
            // };
            

            
        });
    });
    
    
});

let scrollContainer = $(".megamenu .menu-content");
let menuContent = scrollContainer.html();
let breakpoint = getComputedStyle(document.documentElement).getPropertyValue('--menu-up-breakpoint');
let scrollBar;

let createMenuScrollBar = debounce(() => {
    if (window.matchMedia(`(max-width: ${breakpoint})`).matches) {
        if (scrollContainer.length && scrollBar == null) {
            scrollBar = new SimpleBar(scrollContainer[0]);
        }
    } else if (scrollBar != null) {
        scrollBar.unMount();
        scrollContainer.html(menuContent);
        scrollBar = null;
    }
}, 100);

// function mobileMenuOpen(){
//     if($(window).width() < 1600)
//     {
//         $(document).on('click','[data-megamenu-mobile-toggle]', e => {
//             console.log('test kliku');
//             $('.megamenu').toggleClass('megamenu-hidden')
//             e.currentTarget.classList.toggle('megamenu-open');

            
//         })
    // } else {
    //     $(document).on('mouseenter','[data-megamenu-mobile-toggle]', e => {
    //         $('.megamenu').removeClass('megamenu-hidden')
    //         e.currentTarget.classList.removeClass('megamenu-open');
    //     });

        

    //     $(document).on('mouseleave','[data-megamenu-mobile-toggle]', e => {
    //         setTimeout(function(){
    //             $('.megamenu').addClass('megamenu-hidden')
    //             e.currentTarget.classList.addClass('megamenu-open');
    //         }, 500);
    //     });

    //     $(document).on('mouseover','.megamenu-wrapper', function(){
    //         setTimeout(function(){
    //         $('.megamenu').removeClass('megamenu-hidden')
    //         $('[data-megamenu-mobile-toggle]').removeClass('megamenu-open');
    //         }, 400);
    //     });

    //     $(document).on('mouseleave','.megamenu-wrapper', function(){
    //         setTimeout(function(){
    //         $('.megamenu').removeClass('megamenu-hidden')
    //         $('[data-megamenu-mobile-toggle]').removeClass('megamenu-open');
    //         }, 400);
    //     });
//      }
// };



// $(document).ready(function()
// {	
// 	$(window).scroll(function() {
// 		var headerSpaceH = $('#header').outerHeight();
// 		var screenWidth = $(window).width();
		
// 		if ($(this).scrollTop() > headerSpaceH && screenWidth >= 992 ){  
// 			  $(".use-sticky").parents("#header").find(".header-bottom").addClass("scroll-menu");
// 			  $(".use-sticky").parents("#header").find(".header-bottom").addClass(" animated fadeInDown");
// 		}
// 		else{
// 			 $(".use-sticky").parents("#header").find(".header-bottom").removeClass("scroll-menu");
// 			$(".use-sticky").parents("#header").find(".header-bottom").removeClass("animated fadeInDown"); 		
// 		}
// 	});
// 	activeMobile();
// 	$(window).resize(function(){
// 		if($(window).width() < 992)
// 		{
// 			$('.megamenu-wrapper').addClass('pos-mobile-menu');
// 			$('#_mobile_megamenu img').parent('a').addClass("img_banner");
// 			$('.pos-mobile-menu').removeClass('container');					
// 		}
// 		else
// 		{
// 			$('.megamenu-wrapper').removeClass('pos-mobile-menu');
// 			$('.megamenu-wrapper .menu-dropdown').show(); 		
// 		}
// 	});
// 	$('.megamenu .megamenu-wrapper .menu-item').hover(function(){
// 	   var extraWidth       = 0
//        var wrapWidthPopup   = $(this).find('.pos-sub-menu').outerWidth(true); /*include padding + margin + border*/
       
//        /*define top and left of the popup*/
//        var wraper = $('.megamenu-wrapper');
//        var wWraper = wraper.outerWidth();
//        var posWraper = wraper.offset();
//        var pos = $(this).offset();
//        var xLeft = pos.left - posWraper.left; 
// 	    if (wWraper < 1000 ){ 
// 			$(this).parents("body").find('.megamenu-wrapper').css('position', 'static');  
// 		}else {
// 			$(this).parents("body").find('.megamenu-wrapper').css('position', 'relative');   
// 		}
//        if ((xLeft + wrapWidthPopup) > wWraper){
// 			$(this).find('.pos-sub-menu').css('right', 0);
// 		}else {
// 		$(this).find('.pos-sub-menu').css('left',xLeft); 
// 		 if (wrapWidthPopup < wWraper ){ 
// 			$(this).find('.pos-sub-menu').css('left','auto'); 
// 			// $(this).find('.pos-sub-menu').css('marginLeft','-30px'); 
// 		 }
		
// 		}
// 	});
	

// 	$('.megamenu img').parent('a').addClass("img_desktop"); 
// 	$('#_mobile_megamenu img').parent('a').addClass("img_banner"); 
// });
// function activeMobile(){
// 	$('.megamenu-wrapper .menu-item > .icon-drop-mobile').on('click', function(){
// 		if($(this).hasClass('open_menu')) {
// 			$('.megamenu-wrapper .menu-item > .icon-drop-mobile').removeClass( 'open_menu' );   
// 			$(this).removeClass( 'open_menu' );  
// 			$(this).next('.megamenu-wrapper .menu-dropdown').slideUp();
// 			$('.megamenu-wrapper .menu-item > .icon-drop-mobile').next('.megamenu-wrapper .menu-dropdown').slideUp();
// 		}
// 		else {	
// 			$('.megamenu-wrapper .menu-item > .icon-drop-mobile').removeClass( 'open_menu' ); 
// 			$('.megamenu-wrapper .menu-item > .icon-drop-mobile').next('.megamenu-wrapper .menu-dropdown').slideUp();
// 			$(this).addClass( 'open_menu' );   
// 			$(this).next('.megamenu-wrapper .menu-dropdown').slideDown();
	
// 		}
		
// 	});
// 	$('.megamenu-wrapper .pos-menu-col > .icon-drop-mobile').on('click', function(){
// 		if($(this).hasClass('open_menu')) {
// 			$('.megamenu-wrapper .pos-menu-col > .icon-drop-mobile').removeClass( 'open_menu' );   
// 			$(this).removeClass( 'open_menu' );  
// 			$(this).next('.megamenu-wrapper ul.ul-column').slideUp();
// 			$('.megamenu-wrapper .pos-menu-col > .icon-drop-mobile').next('.megamenu-wrapper ul.ul-column').slideUp();
// 		} 
// 		else {	
// 			$('.megamenu-wrapper .pos-menu-col > .icon-drop-mobile').removeClass( 'open_menu' ); 
// 			$('.megamenu-wrapper .pos-menu-col > .icon-drop-mobile').next('.megamenu-wrapper ul.ul-column').slideUp();
// 			$(this).addClass( 'open_menu' );   
// 			$(this).next('.megamenu-wrapper ul.ul-column').slideDown();
	
// 		}
	
// 	});
// 	$('.megamenu-wrapper .submenu-item  > .icon-drop-mobile').on('click', function(){
// 		if($(this).hasClass('open_menu')) {
// 			$('.megamenu-wrapper .submenu-item  > .icon-drop-mobile').removeClass( 'open_menu' );   
// 			$(this).removeClass( 'open_menu' );  
// 			$(this).next('.megamenu-wrapper ul.category-sub-menu').slideUp();
// 			$('.megamenu-wrapper .submenu-item  > .icon-drop-mobile').next('.megamenu-wrapper ul.category-sub-menu').slideUp();
// 		}
// 		else {	
// 			$('.megamenu-wrapper .submenu-item  > .icon-drop-mobile').removeClass( 'open_menu' ); 
// 			$('.megamenu-wrapper .submenu-item  > .icon-drop-mobile').next('.megamenu-wrapper ul.category-sub-menu').slideUp();
// 			$(this).addClass( 'open_menu' );   
// 			$(this).next('.megamenu-wrapper ul.category-sub-menu').slideDown();
	
// 		}
// 	});

	
// }
