import SimpleBar from "simplebar";
import createRipple from "/js/components/ripple.js";
import { createSpin } from "/js/cart.js";
import prestashop from "prestashop";
import toastr from "toastr";
import $ from "jquery";

prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = (html) => {
  function getBlockCartModal() {
    return $("#blockcart-modal");
  }

  let $blockCartModal = getBlockCartModal();
  if ($blockCartModal.length) {
    $blockCartModal.remove();
  }

  $("body").append(html);

  $blockCartModal = getBlockCartModal();
  $blockCartModal.modal("show").on("hidden.bs.modal", (event) => {
    prestashop.emit("updateProduct", {
      reason: event.currentTarget.dataset,
      event: event,
    });
  });
};

let blockCart = ".blockcart";
let cartContent = ".cart-content";
let contentList = ".content-list";
let blockCartCount = ".blockcart__count";
let cartEmpty = ".cart-empty";
let cartFooter = ".cart-footer";
let priceSummary = ".price-summary";
let shoppingCart = ".shopping-cart";
let simpleBarWrapper = ".simplebar-content-wrapper";
let spinner = ".cart-spinner";

function onRemoveFromCart() {
  $(".blockcart .remove-from-cart").on("click", function () {
    $(this).closest(".preview-item").addClass("removed");
    if (!$(".blockcart .content-list .preview-item:not(.removed)").length) {
      $(cartFooter).addClass("removed");
      $(spinner).removeClass("removed");
    }
  });
}

function initBlockCartHover() {
  $(blockCart).hoverIntent(
    function () {
      $(this).addClass("hovered");
    },
    function () {
      $(this).removeClass("hovered");
    }
  );
}

function initBlockCart() {
  let scrollContainer = $("#scrollContainer");
  if (scrollContainer.length) {
    new SimpleBar(scrollContainer[0]);
  }
  $(".blockcart .input-group-btn").on("click", createRipple);
  onRemoveFromCart();
  initBlockCartHover();
}

$(function () {
  initBlockCart();

  $('.cart-line-product-quantity').on( "change", function() {
    location.reload();
  } );

  prestashop.blockcart = prestashop.blockcart || {};

  // var showModal =
  //   prestashop.blockcart.showModal ||
  //   function (modal) {
  //     var $body = $("body");
  //     $body.append(modal);
  //     $body.one("click", "#blockcart-modal", function (event) {
  //       if (event.target.id === "blockcart-modal") {
  //         $(event.target).remove();
  //       }
  //     });
  //   };

  prestashop.on("updateCart", function (event) {
    
    var refreshURL = $(".blockcart").data("refresh-url");
    var requestData = {};
    
    // location.reload();

    if (
      event &&
      event.reason &&
      typeof event.resp !== "undefined" &&
      !event.resp.hasError
    ) {
      requestData = {
        id_customization: event.reason.idCustomization,
        id_product_attribute: event.reason.idProductAttribute,
        id_product: event.reason.idProduct,
        action: event.reason.linkAction,
      };
    }
    if (event && event.resp && event.resp.hasError) {
      prestashop.emit("showErrorNextToAddtoCartButton", {
        errorMessage: event.resp.errors.join("<br/>"),
      });
    }
    $.post(refreshURL, requestData)
      .then(function (resp) {
        // Old cart rendering content:
        // var html = $('<div />').append($.parseHTML(resp.preview));
        // $(".blockcart").replaceWith($(resp.preview).find(".blockcart"));
        // End

        // New cart rendering solution //

        let blockcartIsHovered;
        if ($(".blockcart").hasClass("hovered")) {
          blockcartIsHovered = true;
        }


        let scrollOffsetTop = $(simpleBarWrapper).scrollTop();        

        $(spinner).addClass("removed");

        $(contentList).replaceWith($(resp.preview).find(contentList));
              
        $(priceSummary).replaceWith($(resp.preview).find(priceSummary));
        $(blockCartCount).text($(resp.preview).find(blockCartCount).text());

        // Cart Empty

        let cartIsEmpty = $(cartEmpty);
        let containsCartEmpty = $(resp.preview).find(cartEmpty);
        if (containsCartEmpty.length && !cartIsEmpty.length) {
          $(blockCart).removeClass("active").addClass("inactive");
          $(contentList).remove();
          $(cartFooter).remove();
          $(cartContent).append($(resp.preview).find(cartEmpty));
        }

        if (!containsCartEmpty.length && cartIsEmpty.length) {
          $(cartFooter).removeClass("removed");
          $(blockCart).addClass("active").removeClass("inactive");
          $(cartEmpty).remove();
          $(shoppingCart).append($(resp.preview).find(blockCartCount));
          $(cartContent)
            .append($(resp.preview).find(contentList))
            .append($(resp.preview).find(cartFooter));
        }

        createSpin();
        initBlockCart();
        $(simpleBarWrapper).scrollTop(scrollOffsetTop);

        if (blockcartIsHovered) {
          $(blockCart).addClass("hovered");
        }

        // End

        // if (resp.modal) {
        //   showModal(resp.modal);
        // }
      })
      .fail(function (resp) {
        console.log(resp);
        prestashop.emit("handleError", {
          eventType: "updateShoppingCart",
          resp: resp,
        });
        // message.forEach((message) => {
        //   toastr[state](message);
        // });
      });
  });
});


